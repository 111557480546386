import React, { useState, useEffect } from 'react';
import { TextField, Button, Box, Typography } from '@mui/material';
import { db } from '../firebase';
import { doc, setDoc, getDoc } from 'firebase/firestore';

const JournalEditor = ({ userId, onPublish, isFullScreen }) => {
  const [journalText, setJournalText] = useState('');
  const [lastSaved, setLastSaved] = useState(null);

  useEffect(() => {
    const loadDraft = async () => {
      const draftDoc = await getDoc(doc(db, "drafts", userId));
      if (draftDoc.exists()) {
        setJournalText(draftDoc.data().text);
      }
    };
    loadDraft();
  }, [userId]);

  const saveDraft = async (text) => {
    try {
      await setDoc(doc(db, "drafts", userId), {
        text,
        lastUpdated: new Date()
      });
      setLastSaved(new Date());
    } catch (error) {
      console.error("Error saving draft:", error);
    }
  };

  const handleTextChange = (e) => {
    const newText = e.target.value;
    setJournalText(newText);
    saveDraft(newText);
  };

  const handlePublish = () => {
    onPublish(journalText);
    setJournalText('');
    saveDraft('');
  };

  return (
    <Box sx={{ 
      height: '80vh', 
      display: 'flex', 
      flexDirection: 'column',
      padding: 2
    }}>
      <TextField
        multiline
        fullWidth
        value={journalText}
        onChange={handleTextChange}
        placeholder="Start typing your journal entry..."
        sx={{ 
          flexGrow: 1, 
          mb: '5px',
          '& .MuiInputBase-root': {
            height: '100%',
          },
          '& .MuiInputBase-input': {
            height: '100% !important',
            overflow: 'auto',
          },
        }}
        InputProps={{
          disableUnderline: true,
        }}
        variant="filled"
      />
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center',
        mt: '5px'
      }}>
        <Typography variant="caption">
          {lastSaved && `Last saved: ${lastSaved.toLocaleTimeString()}`}
        </Typography>
        <Button onClick={handlePublish} variant="contained" color="primary">
          Publish
        </Button>
      </Box>
    </Box>

  );
};

export default JournalEditor;